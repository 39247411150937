import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  TableContainer,
  TableCaption,
  Button,
  useDisclosure,
  FormLabel,
  Box,
  Center,
  Heading,
  SimpleGrid,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
// Custom components
import Card from "components/card/Card";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";

import toast from "react-hot-toast";
import { handleError } from "helpers/handleError";

import Select from "react-select";

import { getIGServices } from "services/orderService";
import { placeIGOrder } from "services/orderService";

import { FaQuestionCircle } from "react-icons/fa";

import { useEngagementOrders } from "hooks/actions";
import { excludedServiceCategories, excludeIds, iconMap } from "./data";
import { parseDateFromTimestamp } from "helpers/handleError";
import { useEngagementServices } from "hooks/actions";
export default function InstagramOrders(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedService, setSelectedService] = useState({});
  const [price, setPrice] = useState(null);
  const [quantity, setQuantity] = useState();
  const [url, setUrl] = useState(null);
  const cancelRef = React.useRef();

  const [filteredCategory, setFilteredCategory] = useState([]);

  const { services } = useEngagementServices();
  // console.log(services, "IG SERVICES");

  const removeHttpsFromURl = (link) => {
    if (link.startsWith("https://")) {
      return link.replace("https://", "");
    } else if (link.startsWith("http://")) {
      return link.replace("http://", "");
    } else {
      return link;
    }
  };

  function getTransformedArray(categories) {
    return categories.map((item) => {
      const { icon, color } = iconMap[item] || {
        icon: FaQuestionCircle,
        color: "gray.500",
      };
      return {
        name: item,
        icon: <Icon as={icon} color={color} />,
      };
    });
  }

  const handleOrder = async () => {
    // console.log(url);
    try {
      setLoading(true);
      const response = await placeIGOrder({
        service: selectedService?.id,
        quantity: quantity,
        link: "https://" + removeHttpsFromURl(url),
      });

      console.log(response);
      if (response.ok) {
        toast.success("Request successful", {
          duration: 5000,
        });
        // fetchData();
      } else {
        toast.error(handleError(response.data));
      }
    } catch (error) {
      console.error(error);
    } finally {
      onAlertClose();
      setLoading(false);
    }
  };

  const lowerCaseServiceCategories = excludedServiceCategories.map((service) =>
    service.toLowerCase()
  );

  const fetchServices = async () => {
    if (services?.data) {
      try {
        const response = await getIGServices();
        // console.log(response?.data, "API SERVICES");
        const uniqueCategories = [];
        const objectsArray = response?.data;

        // console.log(objectsArray?.length, "API SERVICES LENGTH");

        // Function to filter out objects with excluded IDs and service categories
        function filterObjects(objects, excludeIds, categories) {
          return objects.filter(
            (obj) =>
              !excludeIds.includes(obj.id) &&
              !categories.includes(obj.group.toLowerCase())
          );
        }

        // Apply the function
        const filteredObjectsArray = filterObjects(
          objectsArray,
          excludeIds,
          lowerCaseServiceCategories
        );

        filteredObjectsArray.forEach((item) => {
          if (!uniqueCategories.includes(item.group)) {
            uniqueCategories.push(item.group);
          }
        });

        let transformedArr = getTransformedArray(uniqueCategories);

        setCategories(transformedArr);
        // console.log(excludeIds?.length, "EXCLUDED LENGTH");
        // console.log(filteredObjectsArray.length, "FILTERED ARRAY LENGTH");

        setCountries(filteredObjectsArray);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const filterBasedOnSelectedCategory = (category) => {
    console.log(category, "SELECTED CATEGORY");
    if (category) {
      const filtered = countries.filter((cat) => cat.group === category.name);
      setFilteredCategory(filtered);
      setSelectedService({
        name: filtered[0]?.name ?? "",
        rate: filtered[0]?.rate ?? "",
        id: filtered[0]?.id ?? "",
      });
    } else {
      setFilteredCategory(countries);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      filterBasedOnSelectedCategory(selectedCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (quantity) {
      //   console.log(selectedCountry.category);

      // if selectedCountry.name contains the word "VERIFIED", use a different formula
      if (selectedService?.name?.includes("VERIFIED")) {
        setPrice("$" + (selectedService?.rate * quantity).toFixed(2));
        setQuantity(1);
      } else {
        setPrice("$" + (selectedService?.rate * (quantity / 1000)).toFixed(2));
      }
    } else {
      setPrice(null);
    }
  }, [quantity, selectedService]);

  const { orders } = useEngagementOrders();
  console.log("ENGAGEMENT", orders);

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#000",
        backgroundColor: isSelected ? "grey.300" : "white",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    onAlertOpen();
    // onClose();
  };

  const bg = useColorModeValue("white", "#1B254B");

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  return (
    <Box pt={{ base: "130px", md: "90px", xl: "150px" }}>
      <SimpleGrid
        mt="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Selection
              </AlertDialogHeader>

              <AlertDialogBody>
                <Box>
                  <Flex alignItems={"center"}>
                    <Text mr={2} fontWeight={"bold"}>
                      Service:{" "}
                    </Text>
                    <Text fontSize="smaller">{selectedService["name"]}</Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text mr={2} fontWeight={"bold"}>
                      Quantity:{" "}
                    </Text>
                    <Text fontSize="smaller">{quantity}</Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text mr={2} fontWeight={"bold"}>
                      Destination URL:{" "}
                    </Text>
                    <Text fontSize="smaller">{url}</Text>
                  </Flex>
                </Box>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => onAlertClose()}>
                  Close
                </Button>
                <Button
                  colorScheme="red"
                  loadingText="Processing..."
                  isLoading={loading}
                  onClick={() => handleOrder()}
                  ml={3}
                >
                  Proceed
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Center>
          <Box
            width={{ sm: "90%", md: "90%", lg: "50%", xl: "50%", "2xl": "50%" }}
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
          >
            {/* <Center> */}
            <Heading size="md" mb={5} mt={-10}>
              Order a service
            </Heading>
            {/* <Center width={"100%"}> */}
            <form onSubmit={handleConfirmation}>
              <Box mb="8">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="name"
                >
                  Category
                </FormLabel>
                <Select
                  options={categories}
                  isSearchable={true}
                  required={true}
                  getOptionLabel={(options) => options["name"]}
                  getOptionValue={(options) => options["name"]}
                  onChange={(val) => setSelectedCategory(val)}
                  styles={colourStyles}
                  formatOptionLabel={(category) => (
                    <Flex gap="2" alignItems="center">
                      <span>{category.icon}</span>
                      <span>{category.name}</span>
                    </Flex>
                  )}
                />
              </Box>
              <Box mb="8">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="4px"
                  htmlFor="name"
                >
                  Service
                </FormLabel>
                <Select
                  options={filteredCategory}
                  isSearchable={true}
                  required={true}
                  value={selectedService}
                  getOptionLabel={(options) => options["name"]}
                  getOptionValue={(options) => options["id"]}
                  onChange={(country) => setSelectedService(country)}
                  styles={colourStyles}
                  formatOptionLabel={(service) => (
                    <Flex gap="2" alignItems="center">
                      <Box
                        paddingX={1}
                        borderRadius={3}
                        bgColor={"blue.300"}
                        textColor={"white"}
                        fontSize={12}
                      >
                        {service.id}
                      </Box>
                      <span>
                        {service.name}
                        {service.rate ? " - Price: $" : null}
                        {service.rate} {service.rate ? "Per 1,000" : ""}
                      </span>
                    </Flex>
                  )}
                />
              </Box>

              {selectedCategory?.name && (
                <Box
                  fontSize={"xs"}
                  bg={bg}
                  // backgroundColor={bg}
                  // bg={"whiteAlpha.200"}
                  color={textColor}
                  px={10}
                  py={5}
                  rounded={"md"}
                  mb={10}
                  // boxShadow="sm"
                >
                  <Text fontSize={"md"} mb={1} fontWeight={"bold"}>
                    Description
                  </Text>
                  {/* <br /> */}
                  <p>⏰ Start: 0 - 24 hours</p>
                  <p>
                    {" "}
                    ⚡ Speed: For fast delivery, always use the service with
                    this emoji ⚡
                  </p>
                  <p>
                    💎 Quality: The higher the price, the better the quality.{" "}
                  </p>
                  <p>♻️ Refill: Available for select services.</p>
                  <p>🌊 Drop: May vary depending on the option you choose.</p>
                  {/* <br></br> */}
                  <p> ⚠️ Important Notes:</p>
                  {/* <br></br> */}
                  <ol>
                    <li>⁠ ⁠This service only works on public accounts.</li>
                    <li>
                      ⁠No refill or refund if the order start count decreases at
                      any time.
                    </li>
                    <li>
                      ⁠ ⁠No refill if the profile is made private or the
                      username is changed during boosting.
                    </li>
                    <li>⁠Automatic refund once status order is cancelled</li>
                  </ol>
                </Box>
              )}

              <Box mb="8">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="name"
                >
                  Configure Quantity
                </FormLabel>

                {selectedService["min"] && (
                  <Text fontSize={12}>
                    Min: {selectedService["min"]} Max : {selectedService["max"]}
                  </Text>
                )}

                <NumberInput
                  variant="brand"
                  onChange={(e) => setQuantity(e)}
                  step={parseInt(selectedService["min"]) ?? 50}
                  defaultValue={parseInt(selectedService["min"]) ?? 0}
                  min={parseInt(selectedService["min"]) ?? 0}
                  max={parseInt(selectedService["max"]) ?? 0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>

              {selectedService && quantity && (
                <Box>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    htmlFor="name"
                  >
                    Price
                  </FormLabel>
                  {price && quantity && (
                    <Input
                      mb={4}
                      variant="brand"
                      value={price}
                      contentEditable={false}
                      //   disabled={true}
                    />
                  )}
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    htmlFor="name"
                  >
                    Link
                  </FormLabel>
                  <Input
                    mb={4}
                    variant="brand"
                    placeholder="https://instagram.com/shawnsmshq"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </Box>
              )}
              <Center>
                <Button
                  isDisabled={!url}
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  // alignSelf="center"
                  fontWeight="500"
                  w=""
                  h="50"
                  my="24px"
                  isLoading={loading}
                  loadingText="Please wait..."
                >
                  Place Order
                </Button>
              </Center>
            </form>
            {/* </Center> */}
          </Box>
        </Center>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <Text
              color={textColor}
              fontSize="18px"
              fontWeight="600"
              lineHeight="100%"
            >
              Orders History
            </Text>
          </Flex>

          <TableContainer>
            <Table variant="striped">
              <TableCaption>All orders</TableCaption>
              <Thead>
                <Tr>
                  <Th>Service ID</Th>
                  <Th>Amount</Th>
                  <Th>Service</Th>
                  <Th>Status</Th>
                  <Th>Reference</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orders?.data?.orders && orders?.data?.orders?.length > 0
                  ? orders?.data?.orders?.slice(0, 10).map((order, index) => (
                      <Tr key={index}>
                        <Td>
                          <Box
                            paddingX={1}
                            borderRadius={3}
                            bgColor={"blue.300"}
                            textColor={"white"}
                            fontSize={12}
                          >
                            {order.service_id}
                          </Box>
                        </Td>
                        <Td>{order.amount.display.toLocaleString()}</Td>
                        <Td>{order.service}</Td>
                        <Td>
                          <Flex align="center">
                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={
                                order.status === "recieved"
                                  ? "green.500"
                                  : order.status === "refunded"
                                  ? "red.500"
                                  : order.status === "pending"
                                  ? "orange.500"
                                  : null
                              }
                              as={
                                order.status === "recieved"
                                  ? MdCheckCircle
                                  : order.status === "refunded"
                                  ? MdCancel
                                  : order.status === "pending"
                                  ? MdOutlineError
                                  : null
                              }
                            />
                            {order.status}
                          </Flex>
                        </Td>
                        <Td>{order.reference}</Td>
                        <Td>
                          {parseDateFromTimestamp(order?.created_at)}
                          {/* {order.status === "refunded"
                            ? `Refunded at: ${dayjs(order.expired_at).format(
                                "MMM D, YYYY h:mm A"
                              )}`
                            : `Expires at: ${moment(
                                new Date(
                                  order?.expired_at * 1000
                                ).toLocaleDateString()
                              ).format("MMM D, YYYY h:mm A")}`} */}
                        </Td>
                      </Tr>
                    ))
                  : null}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Flex gap={5} mb={15} mt={15}>
          <Text fontSize={"smaller"}>
            Join us on Telegram for Updates on Number availability and Discounts{" "}
          </Text>
          <a href="https://t.me/ShawnSMShq" target="_blank" rel="noreferrer">
            <Text textDecoration={"underline"} fontSize={"smaller"}>
              Join Telegram
            </Text>
          </a>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
