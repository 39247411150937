import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import useUser from "hooks/useUser";

export default function Help() {
  const { refreshUser } = useUser();

  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <iframe
        src="https://shawnsms.com/"
        title="ShawnSMS Help"
        width="100%"
        height="500px"
      ></iframe>
    </Box>
  );
}
