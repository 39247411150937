import { Box, Flex, Icon } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";

import moment from "moment";
import { useState } from "react";

export default function OrderStatus({ order }) {
  // const isExpired = moment(order.expires_at).isBefore(moment());
  // console.log(order);

  const handleExpired = () => {
    if (order.status === "expired") {
      alert("Number Expired, Please request for a refund");
    }
    return;
  };

  const [expiration, setExpiration] = useState(0);

  useEffect(() => {
    if (order.status === "active") {
      const intervalID = setInterval(() => {
        setExpiration(
          moment(order.expired_to_date).diff(moment(), "seconds") + "s"
        );
      }, 1000);
    } else {
      setExpiration("");
    }
    return () => {
      clearInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Box onClick={handleExpired}>
      {order.status === "active" ? (
        <Flex direction={"column"}>
          <Box
            paddingX={1}
            borderRadius={3}
            bgColor={"blue.300"}
            textColor={"white"}
            fontSize={12}
            width={"50px"}
            alignItems={"center"}
            textAlign={"center"}
          >
            {expiration}
          </Box>
        </Flex>
      ) : (
        <Flex align="center" fontSize={{ base: "x-small", md: "md" }}>
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={
              order.status === "received"
                ? "green.500"
                : order.status === "refunded"
                ? "red.500"
                : order.status === "expired"
                ? "red.500"
                : order.status === "pending"
                ? "orange.500"
                : null
            }
            as={
              order.status === "received"
                ? MdCheckCircle
                : order.status === "refunded"
                ? MdCancel
                : order.status === "pending"
                ? MdOutlineError
                : null
            }
          />
          {order.status}
        </Flex>
      )}
    </Box>
  );
}
