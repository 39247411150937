import { Box, Button, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Card
      bgColor={"#282828"}
      align="center"
      justifyContent="center"
      width={"60%"}
      mx="auto"
      mt={{ base: "60%", lg: "10%" }}
      py="10%"
      //   border="10px solid #F5D004"
    >
      <Box>
        <Text color="white" fontSize={{ base: "12px", lg: "20px" }}>
          404 - Not Found
        </Text>
        <Text color="white" fontSize={{ base: "12px", lg: "17px" }}>
          The page you are looking for does not exist.
        </Text>
        <Button
          mt={{ base: "10%", lg: "2%" }}
          rounded={"md"}
          variant="brand"
          //   bgColor={""}
          //   color={"black"}
          fontSize={{ base: "12px", lg: "17px" }}
          w={{ sm: "80%", md: "25%" }}
          onClick={() => history.push("/admin/dashboard")}
        >
          Go Home
        </Button>
      </Box>
    </Card>
  );
};

export default NotFoundPage;
