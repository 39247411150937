/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  TableContainer,
  TableCaption,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  // Card as CCard,
  // CardBody,
  // // CardHeader,
  // CardFooter,
  AlertDialogOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
// Custom components
import Card from "components/card/Card";

// Assets
import { MdCopyAll, MdRotateLeft } from "react-icons/md";
import ReactCountryFlag from "react-country-flag";
import toast from "react-hot-toast";
import { handleError } from "helpers/handleError";
import { placeOrder } from "services/orderService";
import Select from "react-select";
import { ThreeDots } from "react-loader-spinner";
import dayjs from "dayjs";
import { getCountryCode } from "helpers/getCountryCode";
import { CgMoreVertical } from "react-icons/cg";
import { cancelOrder } from "services/orderService";
import { FaOpencart } from "react-icons/fa";
import { getOrderPrice } from "services/orderService";
import OrderStatus from "components/sidebar/components/OrderStatus";
import moment from "moment";
import { checkSmsPoolOrder } from "services/orderService";
import { useServices, useCountries, useOrders } from "hooks/actions";
import { useMemo } from "react";
import { useSWRConfig } from "swr";
import { fetchSmsStock } from "services/stockClient";
import { parseDateFromTimestamp } from "helpers/handleError";

export default function OrdersTable(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("red.500", "white");

  const { mutate } = useSWRConfig();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: detailOpen,
    onOpen: openDetail,
    onClose: closeDetail,
  } = useDisclosure();

  const [loading, setLoading] = useState(false);
  // const [gettingSms, setGettingSms] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  // const [orders, setOrders] = useState([]);
  // const [countries, setCountries] = React.useState([]);
  // const [services, setServices] = React.useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const [number, setNumber] = useState();
  const [sms, setSms] = useState(null);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [refunding, setRefunding] = useState(false);
  const [price, setPrice] = useState(null);
  const [priceLoading, setPriceLoading] = useState(false);
  // const
  const [stock, setStock] = useState(null);

  const fetchPrice = async () => {
    try {
      setPriceLoading(true);
      setPrice(null);
      const priceRes = await getOrderPrice({
        service: selectedService?.id,
        country: selectedCountry?.id,
      });

      console.log(priceRes.data);
      if (priceRes.ok) {
        setPrice(priceRes.data.price);
        setStock(priceRes.data.stock);
      } else {
        toast.error(handleError(priceRes.data));
      }
    } catch (error) {
    } finally {
      setPriceLoading(false);
    }
  };

  useEffect(() => {
    if (selectedService.id && selectedCountry.id) {
      fetchPrice();
    }
  }, [selectedService, selectedCountry.id]);
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const handleOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    let eventSource;

    try {
      const response = await placeOrder({
        service: selectedService?.id,
        country: selectedCountry?.id,
      });
      if (response.status === 201) {
        mutate("/orders");
        toast.success("Request successful");
        setSelectedCountry({});
        setSelectedService({});
        setPrice(null);
        setActiveModal("");
        try {
          setActiveOrder(response.data);

          setNumber(response.data?.number);
          const openEventSource = () => {
            eventSource = new EventSource(response?.data?.stream_url);
            eventSource.onopen = () => {
              console.warn(
                "EventSource connection is open for.",
                response?.data?.number
              );
            };
            eventSource.onmessage = (event) => {
              console.log(event);
              console.log("Message received:", event.data);
              setSms(event.data);
              setCode(event?.data?.code);
              eventSource.close();
              setActiveModal("");
            };
            eventSource.onerror = (error) => {
              console.error("EventSource failed:", error);
              eventSource.close();
              setActiveModal("");
            };
          };
          openEventSource();
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error(handleError(response.data));
        setActiveModal("");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setSelectedCountry({});
      setSelectedService({});
      setPrice(null);
      setActiveModal("");
      setLoading(false);
    }
  };

  const { services } = useServices();
  const { countries } = useCountries();
  const { orders } = useOrders();

  const optimisedServices = useMemo(() => {
    console.warn(services, "SERVICES");
    return services?.data ?? [];
  }, [services]);

  const optimisedCountries = useMemo(() => {
    return countries?.data ?? [];
  }, [countries]);

  const doesLogoExist = (service) => {
    try {
      require(`../../../../assets/img/logo/${service.name.toLowerCase()}.png`);
      return true;
    } catch (error) {
      return false;
    }
  };

  async function check(id) {
    const res = await checkSmsPoolOrder(id);
    mutate("/orders");
    console.log(res.data, "CHECK RAN");
  }
  const runCheck = () => {
    console.log("CHECKING ALL ORDERS", orders);
    const allOrders = orders?.data?.orders ?? [];
    // filter all orders with active status
    const activeOrders = allOrders?.filter(
      (order) => order.status === "active"
    );

    if (activeOrders?.length > 0) {
      activeOrders.map((order) => {
        return check(order.id);
      });
    }
  };
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setActiveModal("confirm");
    onClose();
  };

  const copyCode = (value) => {
    navigator.clipboard.writeText(value);
    toast.success("Copied to clipboard");
  };
  const bg = useColorModeValue("white", "#1B254B");

  const processRefund = async () => {
    if (!activeOrder) return;
    setRefunding(true);
    try {
      // setRefunding(true);
      const res = await cancelOrder(activeOrder.id);
      console.log(res, "REFUND RES");
      if (res.ok) {
        toast.success(res.data.message);
        // fetchData();
        setNumber(null);
      } else {
        toast.error(handleError(res.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      onAlertClose();
      setRefunding(false);
      // setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // fetchData();
      runCheck();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [code, orders]);

  const runner = () => {
    console.log("WINDOW IS FOCUSED");
    runCheck();
  };
  useEffect(() => {
    window.addEventListener("focus", runner);
    return window.removeEventListener("focus", runner);
  }, [orders]);

  return (
    <Box pt={{ base: "30px", md: "20px", xl: "80px" }}>
      {sms && (
        <Alert status="success">
          <AlertIcon />
          <AlertTitle>Your Code is ready!</AlertTitle>
          <AlertDescription>{sms}</AlertDescription>
          <Icon
            w="32px"
            h="32px"
            as={MdCopyAll}
            color={brandColor}
            onClick={() => copyCode(sms)}
          />
        </Alert>
      )}

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel & Refund order
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => onAlertClose()}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                loadingText="Processing..."
                isLoading={refunding}
                onClick={() => processRefund()}
                ml={3}
              >
                Refund
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={detailOpen} onClose={closeDetail}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Viewing Order: {activeOrder?.reference}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="striped">
                <TableCaption>
                  <Flex
                    onClick={() => copyCode(activeOrder?.reference)}
                    gap="2"
                    justifyContent="center"
                    alignItems="center"
                  >
                    Order Reference:
                    <Text fontWeight="bold" color="green.200">
                      {activeOrder?.reference}
                    </Text>
                    <Icon
                      w="20px"
                      h="20px"
                      as={MdCopyAll}
                      color={brandColor}
                      // onClick={() => copyCode(number)}
                    />
                  </Flex>
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Key</Th>
                    <Th>Value</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* <Tr>
                    <Td>ID</Td>
                    <Td>
                      <Text
                        width={"40%"}
                        textOverflow={"clip"}
                        wordBreak={"break-word"}
                      >
                        {activeOrder?.id}
                      </Text>
                    </Td>
                  </Tr> */}
                  <Tr>
                    <Td>Status</Td>
                    <Td>{activeOrder?.status}</Td>
                  </Tr>
                  <Tr>
                    <Td>Number?</Td>
                    <Td>
                      {activeOrder?.number && (
                        <Flex
                          onClick={() => copyCode(activeOrder.number)}
                          gap="2"
                          alignItems="center"
                        >
                          <Text fontWeight="bold" color="green.200">
                            +{activeOrder?.number}
                          </Text>
                          <Icon
                            w="25px"
                            h="25px"
                            as={MdCopyAll}
                            color={brandColor}
                            // onClick={() => copyCode(number)}
                          />
                        </Flex>
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Created At</Td>
                    <Td>
                      <Text>
                        {parseDateFromTimestamp(activeOrder?.created_at)}
                      </Text>
                    </Td>
                  </Tr>
                  {activeOrder?.received_at && (
                    <Tr>
                      <Td>Code Recieved At:</Td>
                      <Td>
                        <Text>
                          {parseDateFromTimestamp(activeOrder?.received_at)}
                        </Text>
                      </Td>
                    </Tr>
                  )}
                  {activeOrder?.expires_at && (
                    <Tr>
                      <Td>Expiration</Td>
                      <Td>
                        <Text>
                          {activeOrder?.expires_at
                            ? parseDateFromTimestamp(activeOrder?.expires_at)
                            : "Empty"}
                        </Text>
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>SMS?</Td>
                    <Td>{activeOrder?.content ?? "Empty"}</Td>
                  </Tr>
                  <Tr>
                    <Td>Country</Td>
                    <Td>{activeOrder?.country ?? "Empty"}</Td>
                  </Tr>
                  <Tr>
                    <Td>Service</Td>
                    <Td>{activeOrder?.service ?? "Empty"}</Td>
                  </Tr>
                  {activeOrder?.code && (
                    <Tr>
                      <Td>Code</Td>
                      <Td>
                        <Flex
                          onClick={() => copyCode(activeOrder?.code)}
                          gap="2"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Text fontWeight="bold" color="green.200">
                            {activeOrder?.code}
                          </Text>
                          <Icon
                            w="25px"
                            h="25px"
                            as={MdCopyAll}
                            color={brandColor}
                            // onClick={() => copyCode(number)}
                          />
                        </Flex>{" "}
                      </Td>
                      {/* <Td>{activeOrder?.code ?? ""}</Td> */}
                    </Tr>
                  )}
                  {activeOrder?.refunded_at && (
                    <Tr>
                      <Td>Refunded at</Td>
                      <Td>
                        {dayjs(activeOrder.refunded_at * 1000).format(
                          "MMM D, YYYY h:mm A"
                        )}
                      </Td>
                    </Tr>
                  )}
                  {activeOrder?.expired_at && (
                    <Tr>
                      <Td>Expired at</Td>
                      <Td>
                        {moment(
                          new Date(
                            activeOrder?.expired_at * 1000
                          ).toLocaleDateString()
                        ).format("MMM D, YYYY h:mm A")}
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Amount</Td>
                    <Td>{activeOrder?.amount?.display ?? "Empty"}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={closeDetail}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={activeModal === "confirm"}
        onClose={() => setActiveModal("")}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            {!number ? "Confirm Selection" : "Phone Number Generated"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign="center" mb={5}>
              Are you sure you want to proceed with the selected order below?
              {/* {!number
                ? " Are you sure you want to proceed with the selected order below?"
                : "Number generated successfully, click the copy icon to copy and then paste in your desired service."} */}
            </Text>

            {/* {!number && ( */}
            <Box>
              <Text> Country: {selectedCountry["name"]}</Text>
              <Text> Service: {selectedService["name"]}</Text>
              <Text> Price: {price?.display ?? "Fetching price..."} </Text>
              <Flex
                gap="2"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  my="24px"
                  disabled={loading}
                  isLoading={loading}
                  loadingText="Placing order..."
                  onClick={handleOrder}
                >
                  Place Order
                </Button>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={() => setActiveModal("")}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Center>
        <Box
          width={{ sm: "90%", md: "90%", lg: "50%", xl: "50%", "2xl": "50%" }}
          // justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          {/* <Center> */}
          <Heading size="md" mb={5} mt={-50}>
            Order a service
          </Heading>
          {/* <Center width={"100%"}> */}
          <form onSubmit={handleConfirmation}>
            <Box mb="8">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="name"
              >
                Service
              </FormLabel>

              <Select
                options={optimisedServices}
                isSearchable={true}
                value={selectedService ?? {}}
                placeholder="Select a Service"
                required={true}
                getOptionLabel={(options) => options["name"]}
                getOptionValue={(options) => options["id"]}
                onChange={(service) => setSelectedService(service)}
                styles={colourStyles}
                formatOptionLabel={(service) => (
                  <Flex gap="5" alignItems="center">
                    {doesLogoExist(service) && (
                      <img
                        src={require(`../../../../assets/img/logo/${service.name.toLowerCase()}.png`)}
                        alt={`${service.name} Logo`}
                        width="20"
                        height="20"
                      />
                    )}
                    <span>{service.name}</span>
                  </Flex>
                )}
              />
            </Box>
            <Box mb="8">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="name"
              >
                Country
              </FormLabel>
              <Select
                options={optimisedCountries}
                isSearchable={true}
                required={true}
                value={selectedCountry}
                getOptionLabel={(options) => options["name"]}
                getOptionValue={(options) => options["id"]}
                onChange={(country) => setSelectedCountry(country)}
                styles={colourStyles}
                formatOptionLabel={(country) => (
                  <Flex gap="5" alignItems="center">
                    <ReactCountryFlag
                      countryCode={getCountryCode(country.name)?.toUpperCase()}
                      svg
                    />
                    <span>{country.name}</span>
                  </Flex>
                )}
              />
            </Box>

            {(priceLoading || price?.display) && (
              <Box
                fontSize={"sm"}
                bg={bg}
                // backgroundColor={bg}
                // bg={"whiteAlpha.200"}
                color={textColor}
                px={5}
                py={5}
                rounded={"md"}
                mb={10}
              >
                <Heading size="xs">Pricing & Stock</Heading>
                {priceLoading && (
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={priceLoading}
                    />
                    {/* <Text>Fetching ...</Text> */}
                  </Flex>
                )}
                {price && <Text> Price: {price?.display} </Text>}
                {stock && price && (
                  <Text>
                    {" "}
                    Available Stock: {parseInt(stock).toLocaleString()}{" "}
                  </Text>
                )}
              </Box>
            )}

            <Center>
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                // alignSelf="center"
                fontWeight="500"
                w=""
                h="50"
                my="24px"
                isDisabled={!price}
                isLoading={loading}
                loadingText="Please wait..."
              >
                Place Order
              </Button>
            </Center>
          </form>
          {/* </Center> */}
        </Box>
      </Center>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Orders History
          </Text>
          <Box height={"40px"}>
            <ThreeDots
              //   visible={true}
              height="30"
              width="30"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={ordersLoading}
            />
          </Box>

          {/* <Button
            rightIcon={<Icon as={MdAddCircle} w="24px" h="24px" />}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            size="sm"
            w="fit"
            h="50"
            py={{ base: "10px", md: "10px" }}
            px={{ base: "10px", md: "10px" }}
            onClick={onOpen}
          >
            New Order
          </Button> */}
        </Flex>

        <TableContainer>
          <Table variant="striped">
            <TableCaption>All orders</TableCaption>
            <Thead>
              <Tr>
                <Th>Number</Th>
                <Th>Code</Th>
                <Th>Status</Th>
                <Th>Amount</Th>
                <Th>Service</Th>
                <Th>Options</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders?.data?.orders && orders.data.orders?.length > 0
                ? orders?.data?.orders?.slice(0, 10).map((order, index) => (
                    <Tr key={order.id}>
                      <Td>
                        {order?.number ? (
                          <Flex
                            onClick={() => copyCode(order?.number)}
                            gap="2"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Text
                              fontSize={{ base: "x-small", md: "md" }}
                              fontWeight="bold"
                              color="green.200"
                            >
                              + {order?.number.toString()}
                            </Text>
                            {/* <Icon
                              w="20px"
                              h="20px"
                              as={MdCopyAll}
                              color={brandColor}
                              // onClick={() => copyCode(number)}
                            /> */}
                          </Flex>
                        ) : null}
                      </Td>

                      <Td>
                        {order?.code ? (
                          <Flex
                            onClick={() => copyCode(order.code)}
                            gap="2"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Text
                              fontSize="sm"
                              fontWeight="bold"
                              color="green.200"
                            >
                              {order.code}
                            </Text>
                            <Icon
                              w="25px"
                              h="25px"
                              as={MdCopyAll}
                              color={brandColor}
                              // onClick={() => copyCode(number)}
                            />
                          </Flex>
                        ) : order.status === "active" ? (
                          <ThreeDots
                            //   visible={true}
                            height="30"
                            width="30"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : null}
                      </Td>
                      <Td>
                        <OrderStatus order={order} />
                      </Td>
                      <Td fontSize="x-small">
                        {order.amount.display.toLocaleString()}
                      </Td>

                      <Tr fontSize="x-small">
                        <Td>
                          <p>{order?.country}</p>
                          <p>{order?.service ?? "Empty"}</p>
                        </Td>
                      </Tr>
                      {/* <Td>{order.reference}</Td> */}
                      {/* <Td>s

                        {order.status === "refunded"
                          ? `Refunded at: ${dayjs(order.refunded_at).format(
                              "MMM D, YYYY h:mm A"
                            )}`
                          : `Expires at: ${dayjs(order.expired_at).format(
                              "MMM D, YYYY h:mm A"
                            )}`}
                      </Td> */}
                      <Td fontSize={{ base: "x-small", md: "md" }}>
                        <Menu>
                          <MenuButton
                            bg={"transparent"}
                            // px={4}
                            // py={2}
                            transition="all 0.2s"
                            // borderRadius="md"
                            // borderWidth="1px"
                            // _hover={{ bg: "gray.400" }}
                            // _expanded={{ bg: "blue.400" }}
                            // _focus={{ boxShadow: "outline" }}
                          >
                            <Flex alignItems="center">
                              More <CgMoreVertical />
                            </Flex>
                          </MenuButton>
                          <MenuList
                            style={{
                              position: "absolute",
                              bottom: -5,
                              right: 15,
                            }}
                          >
                            {!order.received_at && !order.refunded_at && (
                              <MenuItem
                                onClick={() => {
                                  setActiveOrder(order);
                                  onAlertOpen();
                                }}
                              >
                                <Icon as={MdRotateLeft} />
                                Request Refund
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => {
                                setActiveOrder(order);
                                openDetail();
                              }}
                            >
                              <Icon as={FaOpencart} mr={1} />
                              View Order
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
      <Flex gap={5} mb={15} mt={15}>
        <Text fontSize={"smaller"}>
          Join us on Telegram for Updates on Number availability and Discounts{" "}
        </Text>
        <a href="https://t.me/ShawnSMShq" target="_blank" rel="noreferrer">
          <Text textDecoration={"underline"} fontSize={"smaller"}>
            Join Telegram
          </Text>
        </a>
      </Flex>
    </Box>
  );
}
