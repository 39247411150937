// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import toast from "react-hot-toast";
import { MdCopyAll } from "react-icons/md";

export default function Banner(props) {
  const {
    banner,
    avatar,
    name,
    referralCode,
    balance,
    email,
    phone,
    twoFA,
    user,
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("black", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const copyCode = (value) => {
    navigator.clipboard.writeText(value);
    toast.success("Referral link copied to clipboard");
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center">
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={avatar}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {user?.name ?? "User's Name"}
      </Text>
      <Text
        color={textColorPrimary}
        // fontWeight="bold"
        fontSize="x-small"
        // mt="10px"
      >
        {user?.email ?? "User's Name"}
      </Text>
      <Flex w="max-content" justifyContent="center" mx="auto" mt="26px">
        <Flex mx="auto" me="20px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="lg" fontWeight="700">
            {balance ?? "$0.00"}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Balance
          </Text>
        </Flex>
        {referralCode && (
          <Flex
            mx="auto"
            align="center"
            direction="column"
            onClick={() =>
              copyCode(
                "https://app.shawnsms.com/auth/register?r=" + referralCode
              )
            }
          >
            {/* <Text
            color={textColorPrimary}
            fontSize="lg"
            fontWeight="700"
            onClick={() => copyCode(referralCode)}
          >
            {referralCode ?? "Loading..."}
          </Text> */}
            <Flex alignItems="center">
              <Text fontSize={"sm"}>{referralCode ?? ""}</Text>
              <MdCopyAll size={20} color="yellow" />
            </Flex>
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              Referral Code
            </Text>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
