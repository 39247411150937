import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  TableContainer,
  TableCaption,
} from "@chakra-ui/react";
import React from "react";
// Custom components
import Card from "components/card/Card";
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";

// import dayjs from "dayjs";
import { useTransactions } from "hooks/actions";
import moment from "moment";
import { parseDateFromTimestamp } from "helpers/handleError";

export default function TransactionsTable(props) {
  const { transactions: data, isLoading } = useTransactions();
  const textColor = useColorModeValue("black", "white");

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Transactions History
        </Text>
      </Flex>

      <TableContainer>
        <Table variant="striped">
          <TableCaption>All transactions</TableCaption>
          <Thead>
            <Tr>
              {/* <Th>ID</Th> */}
              <Th>Amount</Th>
              <Th>Type</Th>
              <Th>Date</Th>
              <Th>Title</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data?.data?.transactions
                ?.slice(0, 10)
                .map((transaction, index) => (
                  <Tr>
                    {/* <Td>{transaction.id}</Td> */}
                    <Td>{transaction?.amount.display}</Td>
                    <Td>
                      <Flex align="center">
                        <Icon
                          w="24px"
                          h="24px"
                          me="5px"
                          color={
                            transaction.type === "credit"
                              ? "green.500"
                              : transaction.type === "debit"
                              ? "red.500"
                              : transaction.type === "pending"
                              ? "orange.500"
                              : null
                          }
                          as={
                            transaction.type === "credit"
                              ? MdCheckCircle
                              : transaction.type === "debit"
                              ? MdCancel
                              : transaction.type === "pending"
                              ? MdOutlineError
                              : null
                          }
                        />
                        {transaction.type}
                      </Flex>
                    </Td>
                    <Td>{parseDateFromTimestamp(transaction.completed_at)}</Td>
                    <Td>{transaction.title}</Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
