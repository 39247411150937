import React, { useState, useContext, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    return token !== null;
  });

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    setAuthenticated(null);

    // refresh browser
    history.push("/auth/sign-in");
  };

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     history.push("/auth/sign-in");
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
