import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { useSettings } from "hooks/actions";

// Mock useSettings hook to simulate fetching announcements
// const useSettings = () => {
//   return [
//     {
//       id: 2,
//       title: "Twitter Giveaway",
//       description: "Lorem Ipsum",
//       image: null,
//       link: null,
//       created_at: "2024-07-12T10:22:09.000000Z",
//       updated_at: "2024-07-12T10:22:09.000000Z",
//       cta: null,
//     },
//     {
//       id: 3,
//       title: "Admin Credit ",
//       description: "Ahshsbhsbhsbhshs",
//       image: null,
//       link: null,
//       created_at: "2024-07-12T10:22:23.000000Z",
//       updated_at: "2024-07-12T10:22:23.000000Z",
//       cta: null,
//     },
//     {
//       id: 4,
//       title: "Shjshjshjshsh",
//       description: "Shsbhbshsbs",
//       image: null,
//       link: null,
//       created_at: "2024-07-12T10:22:41.000000Z",
//       updated_at: "2024-07-12T10:22:41.000000Z",
//       cta: null,
//     },
//   ];
// };

const AnnouncementModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [latestAnnouncement, setLatestAnnouncement] = useState(null);
  const [announcements, setAnnouncements] = useState(null);

  const { settings } = useSettings();

  useEffect(() => {
    if (settings?.data?.announcements) {
      setAnnouncements(Object.values(settings.data?.announcements));
    }
  }, [settings]);

  useEffect(() => {
    if (announcements) {
      const seenAnnouncementId = localStorage.getItem("seenAnnouncementId");
      const latest = announcements[announcements?.length - 1];
      if (latest && latest.id.toString() !== seenAnnouncementId) {
        setLatestAnnouncement(latest);
        onOpen();
      }
    }
  }, [announcements, onOpen]);

  const handleClose = () => {
    if (latestAnnouncement) {
      localStorage.setItem(
        "seenAnnouncementId",
        latestAnnouncement.id.toString()
      );
    }
    onClose();
  };

  return (
    <Box>
      {latestAnnouncement && (
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          motionPreset="slideInBottom"
          isCentered
        >
          <ModalOverlay />
          <ModalContent margin="5">
            <ModalHeader>{latestAnnouncement.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div
                dangerouslySetInnerHTML={{
                  __html: latestAnnouncement?.description,
                }}
              />
            </ModalBody>
            <ModalFooter>
              {latestAnnouncement.cta && latestAnnouncement.link ? (
                <Button
                  colorScheme="brand"
                  variant="brand"
                  mr={3}
                  as="a"
                  href={latestAnnouncement.link ?? "#"}
                  target="_blank"
                >
                  {latestAnnouncement.cta ?? "Learn More"}
                </Button>
              ) : null}
              <Button onClick={handleClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default AnnouncementModal;
