import { getOrders } from "services/orderService";
import { getIGOrders } from "services/orderService";
import { getCountries } from "services/orderService";
import { getIGServices } from "services/orderService";
import { getCountriesByService } from "services/orderService";
import { getServices } from "services/orderService";
import { getTransactions } from "services/userService";
import { getWallet } from "services/userService";
import { getUser } from "services/userService";
import { getSettings } from "services/userService";
import useSWR from "swr";

export function useSettings() {
  const { data, error, isLoading } = useSWR("/settings", getSettings);
  return {
    settings: data,
    isLoading,
    error,
  };
}

export function useTransactions() {
  const { data, error, isLoading } = useSWR("/transactions", getTransactions);
  return {
    transactions: data,
    isLoading,
    error,
  };
}

export function useUserDetails() {
  const { data, error, isLoading } = useSWR("/userDetails", getUser);
  return {
    userDetails: data,
    isLoading,
    error,
  };
}

export function useCryptomusWalletQR() {
  const { data, error, isLoading } = useSWR("/cryptomusWalletQR", getWallet);
  return {
    walletQR: data,
    isLoading,
    error,
  };
}

export function useServices() {
  const { data, error, isLoading } = useSWR("/services", getServices);
  return {
    services: data,
    isLoading,
    error,
  };
}

// export function useEngagementServices() {
//   const { data, error, isLoading } = useSWR("/ig/services", getIGServices);
//   return {
//     services: data,
//     isLoading,
//     error,
//   };
// }

export function useCountries() {
  const { data, error, isLoading } = useSWR("/countries", getCountries);
  return {
    countries: data,
    isLoading,
    error,
  };
}

export function useSuggestedCountries(service) {
  const { data, error, isLoading } = useSWR(
    `/suggestedCountries/${service}`,
    getCountriesByService(service)
  );
  return {
    countries: data,
    isLoading,
    error,
  };
}
export function useOrders() {
  const { data, error, isLoading } = useSWR("/orders", getOrders);
  return {
    orders: data,
    isLoading,
    error,
  };
}

export function useEngagementOrders() {
  const { data, error, isLoading } = useSWR("/engagement/orders", getIGOrders);
  return {
    orders: data,
    isLoading,
    error,
  };
}

export function useEngagementServices() {
  const { data, error, isLoading } = useSWR(
    "/engagement/services",
    getIGServices
  );
  return {
    services: data,
    isLoading,
    error,
  };
}
