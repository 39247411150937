import { apiClient } from "./client";

export const getOrders = () => {
  return apiClient.get("/orders");
};

export const getCountries = () => {
  return apiClient.get("/smspool/countries");
};

export const getServices = () => {
  return apiClient.get("/smspool/services");
};

export const getCountriesByService = (service) => {
  return apiClient.get(`/smspool/countries/${service}`);
};

export const placeOrder = (data) => {
  return apiClient.post("/smspool/order", data);
};

export const cancelOrder = (orderID) => {
  return apiClient.post(`smspool/${orderID}/cancel`);
};

export const getIGServices = () => {
  return apiClient.get("/n1panel/services");
};

export const getIGOrders = () => {
  return apiClient.get("/engagement_orders");
};

export const checkSmsPoolOrder = (orderID) => {
  return apiClient.get(`/smspool/${orderID}/check`);
};
export const placeIGOrder = (data) => {
  return apiClient.post("n1panel/order", data);
};

export const getOrderPrice = (data) => {
  return apiClient.post("/smspool/price", data);
};
