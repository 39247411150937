// import { object, string, ref } from "yup";
import * as yup from 'yup';

import 'yup-phone';

export const registerSchema = yup.object({
  name: yup.string().min(1).max(50).required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  password: yup
    .string()
    .required('Please enter a password')
    .min(8, 'Password must have at least 8 characters'),
  password_confirmation: yup
    .string()
    .required('Password required')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});

export const resetPasswordSchema = yup.object({
  current_password: yup
    .string()
    .required('Please enter a password')
    .min(8, 'Password must have at least 8 characters'),
  password: yup
    .string()
    .required('Please enter a password')
    .min(8, 'Password must have at least 8 characters'),
  password_confirmation: yup
    .string()
    .required('Password required')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});
