import { create } from "apisauce";

const stockClient = create({
  baseURL: "https://api.smspool.net/sms/",
});

export const fetchSmsStock = async (country, service) => {
  const response = await stockClient.post("stock", {
    country,
    service,
    key: "LOKjOTPIyuhEPHkDTKZvkfG8EK3hArDf",
  });
  return response.data;
};
