import React from "react";
import lightLogo from "../../../assets/img/logo.svg";
// import darkLogo from "../../../assets/img/shawnsms-white.png";
// Chakra imports
import { Flex, Image } from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  const logo = lightLogo;
  return (
    <Flex align="left" direction="column">
      <Image
        style={{ width: "150px", height: "auto", objectFit: "contain" }}
        src={logo}
        alt="shawn-sms logo"
      />
      <HSeparator mb="20px" />
      {/* <HSeparator mb="20px" /> */}
    </Flex>
  );
}

export default SidebarBrand;
