import { create } from "apisauce";

const token = localStorage.getItem("token");

export const apiClient = create({
  baseURL: "https://new-be.nexarhq.com/api/v1/user",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

apiClient.addAsyncRequestTransform(async (request) => {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
});

export const apiFormClient = create({
  baseURL: "https://new-be.nexarhq.com/api/v1/user",
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});
