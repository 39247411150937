/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect } from "react";
import useUser from "hooks/useUser";
import { CopyIcon } from "@chakra-ui/icons";
import toast from "react-hot-toast";
import { MdAllOut, MdAttachMoney, MdBarChart } from "react-icons/md";
import TransactionsTable from "./components/TransactionTable";
import { getOrders } from "services/orderService";
import { handleError } from "helpers/handleError";
import FundAccount from "./components/FundAccount";
import { useUserDetails } from "hooks/actions";
import { useOrders } from "hooks/actions";
import AnnouncementModal from "components/card/AnnouncementsModal";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("red.500", "white");
  const boxBg = useColorModeValue("gray.100", "whiteAlpha.100");

  const { userDetails: user, refreshUser } = useUser();
  // const [orders, setOrders] = React.useState(null);
  const { orders } = useOrders();

  // const fetchOrders = async () => {
  //   try {
  //     const response = await getOrders();
  //     setOrders(response?.data?.orders);
  //   } catch (error) {
  //     toast.error(handleError(error));
  //   }
  // };
  const { userDetails } = useUserDetails();
  console.log(userDetails?.data, "USER DETAILS");
  useEffect(() => {
    refreshUser();
    // fetchOrders();
  }, []);

  const copyCode = (value) => {
    navigator.clipboard.writeText(value);
    toast.success("Referral code copied to clipboard");
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mt="40px"
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Balance"
          value={userDetails?.data?.balance?.display ?? "$0.00"}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Referrals"
          value={userDetails?.data?.referrals ?? "0"}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdAllOut} color={brandColor} />}
            />
          }
          name="Total Orders"
          value={orders?.data?.orders?.length ?? "0"}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 1, xl: 1 }}
        gap="20px"
        mt="20px"
        mb="20px"
      >
        <Box alignItems="end" justifyContent="flex-end" flexDirection="row">
          <FundAccount />
        </Box>
        <Flex mt="20px" flexDirection="column" gap="20px">
          <TransactionsTable />
        </Flex>
      </SimpleGrid>

      <AnnouncementModal />
    </Box>
  );
}
