import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdPerson, MdHome, MdHelp } from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import Help from "views/admin/help";
import DataTables from "views/admin/dataTables";

// Auth Imports

import InstagramOrders from "views/admin/dataTables/components/InstagramOrders";
import { RocketIcon } from "components/icons/Icons";
import { PhoneIcon } from "@chakra-ui/icons";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Virtual Number",
    layout: "/admin",
    icon: <Icon as={PhoneIcon} width="20px" height="20px" color="inherit" />,
    path: "/sms-orders",
    component: DataTables,
  },
  {
    name: "Boost Accounts",
    layout: "/admin",
    icon: <Icon as={RocketIcon} width="20px" height="20px" color="inherit" />,
    path: "/instagram",
    component: InstagramOrders,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: "Help",
    layout: "/admin",
    path: "/help",
    icon: <Icon as={MdHelp} width="20px" height="20px" color="inherit" />,
    component: Help,
  },
];

export default routes;
